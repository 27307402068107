import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { CURRENCIES, show_proper_words } from "../../utils/constant";
import {
  confirm_stripe_payment_api,
  get_intent_client_secret,
} from "../../DAL/FeesManagement/FeesManagement";
import { useContentSetting } from "../../Hooks/ContentSetting";

import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";

export default function AddOrUpdateFees({
  onCloseDrawer,
  setFeesList,
  formType,
  selectedObject,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const { userInfo, studentsGroup } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const [textColor, setTextColor] = useState(
    getComputedStyle(document.documentElement).getPropertyValue(
      "--text-primary-color"
    )
  );

  let init_amount = 0;
  let init_currency = "usd";
  let isDisabled = false;
  if (userInfo.fees_setting?.amount && !studentsGroup?.fees_setting?.amount) {
    init_amount = userInfo.fees_setting?.amount;
    init_currency = userInfo.fees_setting?.currency;
    isDisabled = true;
  } else if (studentsGroup?.fees_setting?.amount) {
    init_amount = studentsGroup?.fees_setting?.amount;
    init_currency = studentsGroup?.fees_setting?.currency;
    isDisabled = true;
  }

  const EMPTY_VALUES = {
    amount: init_amount,
    currency: init_currency,
    fee_status: "submitted",
    month: "january",
    year: "2024",
    receipt_slip: "",
  };

  const [state, setState] = useState(EMPTY_VALUES);

  const handleSecureCard = (client_secret, cardElement) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });

    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(async function (result) {
        if (result.error) {
          setIsLoading(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }
        let fee_result = await confirm_stripe_payment_api(state._id);
        if (fee_result.code === 200) {
          enqueueSnackbar(fee_result.message, { variant: "success" });
          setIsLoading(false);
          setFeesList((old) =>
            old.map((obj) => {
              if (obj._id === state._id) {
                return { ...obj, fee_status: "submitted" };
              }
              return obj;
            })
          );
          onCloseDrawer();
        } else {
          enqueueSnackbar(fee_result.message, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const getIntentClientSecret = async (cardElement, postData) => {
    const result = await get_intent_client_secret(postData);
    if (result.code === 200) {
      handleSecureCard(result.client_secret, cardElement);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (elements == null) {
      return;
    }
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
    stripe.createToken(cardElement).then(function (result) {
      if (result.error) {
        setIsLoading(false);
        enqueueSnackbar(result.error.message, { variant: "error" });
        return;
      }
      let postData = {
        request_id: state._id,
      };
      getIntentClientSecret(cardElement, postData);
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    if (formType === "EDIT") {
      setState(selectedObject);
    }
  }, [formType, selectedObject]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                type="number"
                className="mt-4 form-control"
                id="amount"
                label="Amount"
                variant="outlined"
                name="amount"
                value={state.amount}
                onChange={handleChange}
                required
                inputProps={{ min: 1 }}
                disabled={Boolean(formType === "EDIT" || isDisabled)}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="currency">Currency</InputLabel>
                <Select
                  labelId="currency"
                  id="currency"
                  label="Currency"
                  name="currency"
                  className="mui-select"
                  disabled={Boolean(isDisabled || formType === "EDIT")}
                  value={state.currency}
                  onChange={handleChange}
                >
                  {CURRENCIES.map((currency) => {
                    return (
                      <MenuItem value={currency.value}>
                        {currency.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="month"
                label="Month"
                variant="outlined"
                name="month"
                value={show_proper_words(state.month)}
                onChange={handleChange}
                required
                inputProps={{ min: 1 }}
                disabled={Boolean(formType === "EDIT" || isDisabled)}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="year">Year</InputLabel>
                <Select
                  labelId="year"
                  id="year"
                  label="Year"
                  name="year"
                  className="mui-select"
                  value={state.year}
                  disabled={Boolean(formType === "EDIT")}
                  onChange={handleChange}
                >
                  <MenuItem value="2023">2023</MenuItem>
                  <MenuItem value="2024">2024</MenuItem>
                  <MenuItem value="2025">2025</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-12 mt-4">
              <div className="custom-card-element">
                <CardElement
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        iconColor: textColor, // 🔥
                        color: textColor, // 🔥
                        "::placeholder": {
                          color: textColor, // 🔥
                        },
                        fontSize: "18px",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
