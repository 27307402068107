import React, { useContext, useRef, useState } from "react";
import { useContentSetting } from "./ContentSetting";
import { useEffect } from "react";
import { tune_file, ended_call_tune } from "../assets";
import Peer from "peerjs";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

let micError =
  "Microphone access is denied. Please grant permission in your browser settings.";

let cameraError =
  "Camera permission is denied. Please enable it in your browser settings.";

const constraints = {
  video: {
    width: 1280,
    height: 720,
    aspectRatio: 16 / 9,
  },
  audio: true,
};

let peerConfig = {
  config: {
    iceServers: [{ url: "stun:stun.l.google.com:19302" }],
  },
};

const CreateClassRoom = React.createContext();
export const useClassRoom = () => useContext(CreateClassRoom);
export function ClassRoom({ children }) {
  const tune = new Audio(tune_file);
  const ended_call_file = new Audio(ended_call_tune);
  const isDisconnectingRef = useRef(false);
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [myPeer, setMyPeer] = useState(null);
  const [myStream, setMyStream] = useState(null);
  const [hostStream, setHostStream] = useState(null);
  const [lastCallID, setLastCallID] = useState(null);
  const [hostPeerId, setHostPeerId] = useState(null);
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const [isCallAccepting, setIsCallAccepting] = useState(false);
  const [callUser, setCallUser] = useState("");
  const { socket, userInfo, startedMeeting } = useContentSetting();
  const [startCallTune, setStartCallTune] = useState(tune);
  const [endedCallTune, setEndedCallTune] = useState(ended_call_file);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [micOn, setMicOn] = useState(true);
  const [cameraOn, setCameraOn] = useState(false);
  const [isSharedScreen, setIsSharedScreen] = useState(false);

  const handleStopAudio = () => {
    startCallTune.pause();
    startCallTune.currentTime = 0;
  };

  const GetAudioStream = async () => {
    let stream = null;
    const mic = await navigator.permissions.query({ name: "microphone" });
    if (mic.state === "denied") {
      return null;
    }
    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      if (error.name === "NotAllowedError") {
        stream = null;
      } else {
        stream = null;
      }
    }
    setMyStream(stream);
    return stream;
  };

  const GetVideoStream = async () => {
    let stream = null;
    const camera = await navigator.permissions.query({ name: "camera" });
    if (camera.state === "denied") {
      return;
    }
    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
      if (error.name === "NotAllowedError") {
        stream = null;
      } else {
        stream = null;
      }
    }
    return stream;
  };

  const handleIncommingCall = async (data) => {
    let { from, last_message, peer_id } = data;
    let getStream = await GetAudioStream();
    if (!getStream) {
      enqueueSnackbar(micError, { variant: "error" });
      socket.emit("permissions_error", {
        to: from,
        from: userInfo._id,
        last_call_id: last_message._id,
      });
      return;
    }
    getStream.getTracks().forEach((track) => track.stop());
    startCallTune.play();
    startCallTune.addEventListener("ended", () => {
      startCallTune.play();
    });
    setCallUser(from);
    setHostPeerId(peer_id);
    setLastCallID(last_message._id);
    socket.emit("ringing:call", {
      to: from,
      from: userInfo._id,
      last_call_id: last_message._id,
    });
    setIsIncomingCall(true);
  };

  const handleCallAccepted = async () => {
    handleStopAudio();
    setIsIncomingCall(false);
  };

  const handleToggleCam = async () => {
    let stream = null;
    let error = "";
    if (!cameraOn) {
      stream = await GetVideoStream();
      error = cameraError;
    } else {
      stream = await GetAudioStream();
      error = micError;
    }
    if (!stream) {
      enqueueSnackbar(error, { variant: "error" });
      return;
    }
    if (myStream) {
      myStream.getTracks().forEach((track) => track.stop());
    }
    const call = myPeer.call(hostPeerId, stream);
    if (!micOn) {
      stream.getAudioTracks().forEach((track) => (track.enabled = false));
    }
    setMyStream(stream);
    call.on("stream", (hostStream) => {
      setHostStream(hostStream);
    });

    const videoTracks = myStream.getVideoTracks();
    videoTracks.forEach((track) => (track.enabled = !cameraOn));

    socket.emit("changed_media", {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
      media_type: "is_paused",
      media_status: cameraOn,
    });
    setCameraOn(!cameraOn);
  };

  const handleStopCall = ({ data }) => {
    const { last_call_id } = data;
    if (last_call_id && last_call_id === lastCallID) {
      if (myStream) {
        myStream.getTracks().forEach((track) => track.stop());
        setMyStream(null);
      }
      setHostStream(null);
      setLastCallID(null);
      if (myPeer) {
        myPeer.destroy();
        setMyPeer(null);
      }
      setIsCallAccepted(false);
      handleStopAudio();
      setCallUser("");
      setMicOn(true);
      setCameraOn(false);
      setIsSharedScreen(false);
      endedCallTune.play();
      isDisconnectingRef.current = false;
      setIsDisconnecting(false);
    }
  };

  const acceptCall = async () => {
    if (isCallAccepting) return;
    let stream = await GetAudioStream();
    if (!stream) {
      enqueueSnackbar(micError, { variant: "error" });
      socket.emit("permissions_error", {
        to: callUser,
        from: userInfo._id,
        last_call_id: lastCallID,
      });
      return;
    }

    setIsCallAccepting(true);
    setMyStream(stream);
    var peer = new Peer(peerConfig);
    setMyPeer(peer);

    peer.on("open", (id) => {
      const call = peer.call(hostPeerId, stream);
      if (call) {
        const acceptData = {
          to: callUser,
          from: userInfo._id,
          peer_id: id,
          type: "student",
          last_call_id: lastCallID,
        };
        socket.emit("call:accepted", acceptData);
      }
      call.on("stream", (hostStream) => {
        setIsIncomingCall(false);
        setIsCallAccepting(false);
        setIsCallAccepted(true);
        setHostStream(hostStream);
        if (isDisconnectingRef.current) {
          let message = "School's Connection has been restored";
          enqueueSnackbar(message, { variant: "info" });
        }
        isDisconnectingRef.current = false;
        setIsDisconnecting(false);
      });
    });
    peer.on("call", (call) => {
      call.answer(stream);
      call.on("stream", (remoteStream) => {
        setHostStream(remoteStream);
      });
    });

    peer.on("error", (err) => {
      console.error("PeerJS Error:", err);
    });
  };

  const handleUserRejoined = (data) => {
    const { peer_id } = data;
    const call = myPeer.call(peer_id, myStream);
    call.on("stream", (hostStream) => {
      setHostStream(hostStream);
      isDisconnectingRef.current = false;
      setIsDisconnecting(false);
      let message = "School's Connection has been restored";
      enqueueSnackbar(message, { variant: "info" });
    });
  };

  const handleEndCall = ({ call_event, stop_call }) => {
    if (isCallAccepting) return;
    let event = "call:canceled";
    if (call_event) {
      event = call_event;
    }
    if (isIncomingCall) {
      event = "call:declined";
    } else if (isCallAccepted) {
      event = "call:ended";
    }

    socket.emit(event, {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
    });

    if (stop_call) {
      handleStopCall({ data: { last_call_id: lastCallID } });
    }
  };

  const handleRejoin = async () => {
    const { participants, _id, sender_id } = startedMeeting;
    let user = participants.find((p) => p.member === sender_id);
    let i_am = participants.find((p) => p.member === userInfo._id);

    let stream = await GetAudioStream();
    if (!stream) {
      enqueueSnackbar(micError, { variant: "error" });
      socket.emit("permissions_error", {
        to: user.member,
        from: userInfo._id,
        last_call_id: startedMeeting._id,
      });
      return;
    }

    if (user && i_am) {
      const { member, peer_id, is_screen_shared } = user;
      const { is_paused, is_muted } = i_am;
      if (is_screen_shared || !is_paused) {
        stream.getTracks().forEach((track) => track.stop());
        stream = await GetVideoStream();
        if (!stream) {
          enqueueSnackbar(cameraError, { variant: "error" });
          socket.emit("permissions_error", {
            to: user.member,
            from: userInfo._id,
            last_call_id: startedMeeting._id,
          });
          return;
        }
        stream.getVideoTracks().forEach((track) => (track.enabled = true));
      }
      setMyStream(stream);
      stream.getAudioTracks().forEach((track) => (track.enabled = !is_muted));
      setCameraOn(!is_paused);
      setMicOn(!is_muted);
      setIsSharedScreen(is_screen_shared);

      var peer = new Peer(peerConfig);
      setMyPeer(peer);
      setCallUser(member);

      peer.on("open", (id) => {
        let callData = {
          to: member,
          from: userInfo._id,
          peer_id: id,
          last_call_id: _id,
        };
        socket.emit("user:rejoined", callData);

        const call = peer.call(peer_id, stream);
        call.on("stream", (hostStream) => {
          setIsIncomingCall(false);
          setIsCallAccepted(true);
          setHostStream(hostStream);
          setLastCallID(_id);
        });
      });

      peer.on("error", (err) => {
        console.error("PeerJS Error:", err);
      });
    }
  };

  const handleChangeMedia = async (data) => {
    const { media_type, media_status } = data;
    if (media_type === "is_screen_shared") {
      setIsSharedScreen(media_status);
    }
  };

  useEffect(() => {
    socket.on("incoming:call", handleIncommingCall);
    socket.on("call:accepted", handleCallAccepted);
    socket.on("call:canceled", handleStopCall);
    socket.on("call:ended", handleStopCall);
    socket.on("call:declined", handleStopCall);
    socket.on("call:no_answered", handleStopCall);
    socket.on("user:rejoined", handleUserRejoined);
    socket.on("changed_media", handleChangeMedia);

    return () => {
      socket.off("incoming:call", handleIncommingCall);
      socket.off("call:accepted", handleCallAccepted);
      socket.off("call:canceled", handleStopCall);
      socket.off("call:ended", handleStopCall);
      socket.off("call:declined", handleStopCall);
      socket.off("call:no_answered", handleStopCall);
      socket.off("user:rejoined", handleUserRejoined);
      socket.off("changed_media", handleChangeMedia);
    };
  }, [socket, myPeer, myStream, lastCallID, pathname]);

  useEffect(() => {
    let timeoutId;
    const callDisconnecting = (data) => {
      let { last_call_id } = data;
      if (last_call_id === lastCallID) {
        isDisconnectingRef.current = true;
        setIsDisconnecting(true);
        setIsSharedScreen(false);
        timeoutId = setTimeout(() => {
          if (isDisconnectingRef.current) {
            handleEndCall({ call_event: "call:ended" });
          }
        }, 30000);
      }
    };

    if (!isDisconnectingRef.current) {
      clearTimeout(timeoutId);
    }
    socket.on("call-disconnecting", callDisconnecting);
    return () => {
      socket.off("call-disconnecting", callDisconnecting);
      clearTimeout(timeoutId); // Clear the timeout on unmount
    };
  }, [lastCallID, isDisconnectingRef, pathname]);

  useEffect(() => {
    if (startedMeeting) {
      handleRejoin();
    }
  }, [startedMeeting]);

  const collection = {
    isIncomingCall,
    callUser,
    startCallTune,
    lastCallID,
    isCallAccepted,
    hostStream,
    myStream,
    cameraOn,
    micOn,
    isDisconnecting,
    isSharedScreen,
    isCallAccepting,
    setMicOn,
    setCameraOn,
    setCallUser,
    setMyPeer,
    setIsIncomingCall,
    handleStopAudio,
    setIsCallAccepted,
    setHostStream,
    setMyStream,
    acceptCall,
    handleEndCall,
    handleToggleCam,
  };

  return (
    <CreateClassRoom.Provider value={collection}>
      {children}
    </CreateClassRoom.Provider>
  );
}
