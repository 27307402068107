import React from "react";
import Routers from "./routes";
import "./assets/css/style.css";
import { SnackbarProvider } from "notistack";
import { Button, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ContentSettingState } from "./Hooks/ContentSetting";
import ThemeConfig from "./theme";
import HeadController from "./layouts/dashboard/HeadController";

function App() {
  const notistackRef = React.createRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <div className="App">
      {localStorage.getItem("associate_user")?.student_portal_setting && (
        <HeadController
          data={
            JSON.parse(localStorage.getItem("associate_user"))
              .student_portal_setting
          }
        />
      )}
      <ThemeConfig>
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          maxSnack={3}
          action={(key) => (
            <Button
              className="snackbar-cross-icon"
              onClick={onClickDismiss(key)}
            >
              <CloseIcon />
            </Button>
          )}
        >
          <ContentSettingState>
            <Routers />
          </ContentSettingState>
        </SnackbarProvider>
      </ThemeConfig>
    </div>
  );
}

export default App;
