import { Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function LessonBooks() {
  return (
    <div className="container-fluid theme-card mt-3 p-5">
      <div className="row pb-5">
        <div className="col-12 pb-3">
          <h2>Lesson Books</h2>
        </div>
        <Divider className="sidebar-divider" />
        <div className="col-sm-12 col-md-6 col-lg-4 p-3 mt-4">
          <div className="lesson-book-card">
            <Link to="/lesson-books/quran-pak">
              <h2>Holy Quran</h2>
            </Link>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4 p-3 mt-4">
          <div className="lesson-book-card">
            <Link to="/lesson-books/quida-english">
              <h2>Noorani Quida</h2>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
