import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get_min_max_page_value } from "../../utils/constant";
import { quranPagesBaseUrl } from "../../config/config";

export default function HolyQuranIndexMainPage() {
  const params = useParams();
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const part_index = +params.index.replace(/\D/g, ""); // getting part index from params
  const page_index = +params.page_index.replace(/\D/g, ""); // getting part index from params

  useEffect(() => {
    const min_value = get_min_max_page_value(part_index).min;
    const max_value = get_min_max_page_value(part_index).max;
    setMinValue(min_value);
    setMaxValue(max_value);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page_index]);

  return (
    <div className="container-fluid theme-card mt-3 p-5">
      <div className="row pb-5">
        <div className="col-12">
          <div className="back-button-box">
            <Link to={`/lesson-books/quran-pak/${part_index}`}>
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>
        </div>
        <div className="col-12 holy-quran-index-pages">
          <div className="text-center lesson-main-page-number mb-3 arrows-on-main-page">
            <h2>
              {minValue < page_index && (
                <Link
                  to={`/lesson-books/quran-pak/part-index-${part_index}/page-index-${
                    Number(page_index) - 1
                  }`}
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>
              )}{" "}
              &nbsp;&nbsp;&nbsp;Holy Quran Page {page_index}&nbsp;&nbsp;&nbsp;
              {maxValue > page_index && (
                <Link
                  to={`/lesson-books/quran-pak/part-index-${part_index}/page-index-${
                    Number(page_index) + 1
                  }`}
                >
                  <i className="fas fa-arrow-right"></i>
                </Link>
              )}
            </h2>
          </div>
          <img
            src={`${quranPagesBaseUrl}Para ${part_index}/${page_index}.jpg`}
            alt=""
            className="img-fluid w-100"
          />
          <div className="text-center lesson-main-page-number arrows-on-main-page">
            <h2>
              {minValue < page_index && (
                <Link
                  to={`/lesson-books/quran-pak/part-index-${part_index}/page-index-${
                    Number(page_index) - 1
                  }`}
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>
              )}{" "}
              &nbsp;&nbsp;&nbsp;Holy Quran Page {page_index}&nbsp;&nbsp;&nbsp;
              {maxValue > page_index && (
                <Link
                  to={`/lesson-books/quran-pak/part-index-${part_index}/page-index-${
                    Number(page_index) + 1
                  }`}
                >
                  <i className="fas fa-arrow-right"></i>
                </Link>
              )}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
