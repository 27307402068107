import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

export default function DashboardSlider({
  max_value,
  show_title,
  slider_value,
}) {
  const marks = [
    {
      value: 1,
      label: `Page 1`,
    },
    {
      value: max_value,
      label: `Page ${max_value}`,
    },
  ];

  return (
    <div className="mt-5">
      <Box sx={{ width: "100%" }}>
        <Slider
          className="mui-slider"
          aria-label="Restricted values"
          defaultValue={slider_value}
          max={max_value}
          disabled
          valueLabelDisplay="on"
          valueLabelFormat={show_title}
          marks={marks}
        />
      </Box>
    </div>
  );
}
