import { Box, Divider, Drawer, Toolbar } from "@mui/material";
import React from "react";
import { logo } from "../../assets";
import NavSection from "../../components/NavSection";
import sidebarConfig from "./SidebarConfig";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { s3baseUrl } from "../../config/config";

export default function AppSidebar({
  drawerWidth,
  handleDrawerToggle,
  mobileOpen,
}) {
  const { userInfo, conversation, associateUser } = useContentSetting();
  let student_portal_setting = associateUser?.student_portal_setting;

  const drawer = (
    <div>
      <Toolbar>
        <img
          src={
            student_portal_setting?.brand_logo
              ? s3baseUrl + student_portal_setting?.brand_logo
              : logo
          }
          alt=""
          className="app-logo"
        />
        <h2 className="app-logo-name">
          {student_portal_setting?.brand_name ?? "Rabbani School"}
        </h2>
      </Toolbar>
      <Divider className="sidebar-divider" />
      <NavSection navConfig={sidebarConfig(conversation, userInfo)} />
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      className="app-sidebar-box"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#092329",
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        className="sidebar-permanent-drawer"
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 260,
            backgroundColor: "#092329",
            marginLeft: `${drawerWidth === 0 ? "-260" : "0"}px`,
          },
        }}
        open={Boolean(drawerWidth)}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
