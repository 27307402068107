import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { quaidaPagesBaseUrl } from "../../config/config";

export default function QuidaEnglishIndexPages() {
  const [pageImages, setPageImages] = useState([]);

  useEffect(() => {
    let pages = [];
    for (let index = 1; index <= 33; index++) {
      pages.push(index);
    }
    setPageImages(pages);
  }, []);

  return (
    <div className="container-fluid theme-card mt-3 p-5">
      <div className="row pb-5">
        <div className="col-12 pb-3 d-flex">
          <div className="back-button-box">
            <Link to={`/lesson-books/quran-pak`}>
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>
          <h2>Noorani Quida</h2>
        </div>
        <Divider className="sidebar-divider mb-4" />
        {pageImages.map((page, index) => {
          return (
            <div className="col-sm-12 col-md-4 col-lg-2 mb-3 holy-quran-index-pages">
              <Link to={`/lesson-books/quida-english/page-index-${page}`}>
                <img
                  src={`${quaidaPagesBaseUrl}NQ-eng-${page}.jpg`}
                  alt=""
                  className="img-fluid"
                />
              </Link>
              <Link to={`/lesson-books/quida-english/page-index-${page}`}>
                <div className="text-center">
                  <h2>Page No {page}</h2>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
