import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import AddOrUpdateFees from "../../components/FeesManagement/AddOrUpdateFees";
import { fees_management_list_api } from "../../DAL/FeesManagement/FeesManagement";
import { s3baseUrl } from "../../config/config";
import { currency_sign, show_proper_words } from "../../utils/constant";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51IixJ5B8cGNRhIop9pexqqDiy8WePWF8OfrhLaIVP7hSR9hYlV4vVxzzyJumfQbEdZGQSDysmcXMUEnAqgo8EhaB008GAEb2FN"
);

export default function FeesManagement() {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [feesList, setFeesList] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setFormType("ADD");
  };

  const get_fees_list = async () => {
    const result = await fees_management_list_api();
    if (result.code === 200) {
      const data = result.fee_requests.map((request) => {
        let table_avatar = {
          src: s3baseUrl + request.receipt_slip,
          alt: "Receipt slip",
        };
        return { ...request, table_avatar };
      });
      setFeesList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handlePayNow = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "currency",
      label: "Total Fee",
      renderData: (row) => (
        <div>{currency_sign(row.currency) + row.amount}</div>
      ),
    },
    {
      id: "month",
      label: "Month",
      className: "text-capitalize",
    },
    {
      id: "year",
      label: "Year",
    },
    {
      id: "fee_status",
      label: "Fee Status",
      renderData: (row) => {
        let fee_status = row.fee_status;
        let class_name = "";
        if (fee_status === "pending") {
          class_name = "bg-danger";
        } else if (fee_status === "submitted") {
          class_name = "bg-primary";
        }
        return (
          <span className={`fee-status ${class_name}`}>
            {show_proper_words(fee_status)}
          </span>
        );
      },
    },
    {
      id: "table_avatar",
      label: "Receipt Slip",
      type: "thumbnail",
      show_online: true,
      className: "reduce-cell-padding",
    },
    {
      label: "Pay Now",
      renderData: (row) => {
        if (row.fee_status !== "pending") return;
        return (
          <span className="pay-now-button" onClick={() => handlePayNow(row)}>
            Pay Now
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    get_fees_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Fees Management</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button onClick={handleOpenDrawer}>Add Fee Request</button>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={feesList} />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "EDIT" ? "Update" : "Add"} Fee Request`}
        componentToPassDown={
          <Elements stripe={stripePromise}>
            <AddOrUpdateFees
              formType={formType}
              onCloseDrawer={handleCloseDrawer}
              setFeesList={setFeesList}
              selectedObject={selectedObject}
            />
          </Elements>
        }
      />
    </>
  );
}
