import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import ChattingMessages from "../../components/Chatting/ChattingMessages";
import {
  messages_list_api,
  read_messages_api,
} from "../../DAL/Messages/Messages";
import { useContentSetting } from "../../Hooks/ContentSetting";
import InputEditor from "../../components/GeneralComponents/InputEditor";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";

export default function Chatting() {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, conversation, socket } = useContentSetting();
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const [selectedObject, setSelectedObject] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  const scroll_to_bottom_on_message = () => {
    const chat_box = document.getElementById("chat-box-body");
    setTimeout(() => {
      if (chat_box) {
        if (isLoadingFirst) {
          chat_box.scrollTop = chat_box.scrollHeight;
          setIsLoadingFirst(false);
        } else {
          chat_box.scroll({ top: chat_box.scrollHeight, behavior: "smooth" });
        }
      }
    }, 100);
  };

  const get_messages_list = async () => {
    setIsLoadingMessages(true);
    setIsLoadingFirst(true);
    const result = await messages_list_api(conversation?._id);
    if (result.code === 200) {
      setMessagesList(result.messages_list);
      setTimeout(() => {
        read_messages_api(conversation._id);
      }, 1000);
      setIsLoadingMessages(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMessages(false);
    }
  };

  useEffect(() => {
    get_messages_list();
  }, [conversation]);

  useEffect(() => {
    scroll_to_bottom_on_message();
  }, [messagesList, userInfo._id]);

  const handle_message_receiver = (result) => {
    if (result.code === 200) {
      let members = result.members;
      let find_member = members.find((member) => member._id === userInfo._id);
      if (find_member) {
        setMessagesList((old) => [...old, result.message]);
        read_messages_api(conversation._id);
      }
      if (result.message.sender_id === userInfo._id) {
        setMessageText("");
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const delete_message_from_class_receiver = (result) => {
    setMessagesList((old) =>
      old.filter((message) => message._id !== result.data.message_id)
    );
  };

  const handle_update_message_receiver = (result) => {
    if (result.data.conversation_id === conversation?._id) {
      setMessagesList((messages) => {
        return messages.map((old) => {
          if (old._id === result.last_message._id) {
            return result.last_message;
          } else {
            return old;
          }
        });
      });
    }
  };

  const update_call_status = (values, status) => {
    const chat_message = values.message;
    let last_call_id = chat_message._id;
    setMessagesList((old) => {
      return old.map((message) => {
        if (message._id === last_call_id) {
          if (status === "ended") {
            return chat_message;
          }
          return { ...message, call_status: status };
        }
        return message;
      });
    });
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const handleDelete = () => {
    setOpenDelete(false);
    let formData = {
      conversation_id: conversation?._id,
      sender_id: userInfo._id,
      sender_name: userInfo.name,
      message_id: selectedObject._id,
      user_type: "student",
    };
    socket.emit("delete_message_from_class", formData);
  };

  const handleMenu = () => {
    const MENU_OPTIONS = [
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
    ];
    return MENU_OPTIONS;
  };

  useEffect(() => {
    socket.on("send_message_from_class_receiver", (data) => {
      handle_message_receiver(data);
    });
    socket.on("delete_message_from_class_receiver", (data) => {
      delete_message_from_class_receiver(data);
    });
    socket.on("update_message_from_class_receiver", (data) => {
      handle_update_message_receiver(data);
    });
    socket.on("call:accepted", (data) => {
      update_call_status(data, "started");
    });
    socket.on("call:canceled", (data) => {
      update_call_status(data, "canceled");
    });
    socket.on("call:ringing", (data) => {
      update_call_status(data, "ringing");
    });
    socket.on("call:declined", (data) => {
      update_call_status(data, "declined");
    });
    socket.on("call:ended", (data) => {
      update_call_status(data, "ended");
    });
    socket.on("call:no_answered", (data) => {
      update_call_status(data, "no_answered");
    });
    socket.on("call:unavailable", (data) => {
      update_call_status(data, "unavailable");
    });
    socket.on("permissions_error", (data) => {
      update_call_status(data, "permissions_error");
    });

    return () => {
      socket.off("send_message_from_class_receiver");
      socket.off("update_message_from_class_receiver");
      socket.off("delete_message_from_class_receiver");
      socket.off("call:ringing");
      socket.off("call:unavailable");
      socket.off("permissions_error");
    };
  }, []);

  const inputData = {
    messageText,
    setMessageText,
    conversation,
  };

  return (
    <>
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col-12">
            <div className="chatting-section">
              <ChattingMessages
                messagesList={messagesList}
                isLoadingMessages={isLoadingMessages}
                handleMenu={handleMenu}
              />
              <InputEditor {...inputData} />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this message?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
