import React from "react";
import ParticipantView from "./ParticipantView";
import { useState } from "react";
import { useEffect } from "react";
import { useClassRoom } from "../../../Hooks/ClassRoom";
import { CircularProgress, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import { logo } from "../../../assets";
import { useContentSetting } from "../../../Hooks/ContentSetting";

export default function MeetingView(props) {
  const {
    isIncomingCall,
    callUser,
    startCallTune,
    lastCallID,
    isCallAccepted,
    myStream,
    acceptCall,
    micOn,
    setMicOn,
    cameraOn,
    handleEndCall,
    isCallAccepting,
    handleToggleCam,
  } = useClassRoom();
  const { userInfo, socket } = useContentSetting();
  const { setBoxType, boxType } = props;
  const [isPlaying, setIsPlaying] = useState(true);

  const handletoggleAudio = () => {
    if (!startCallTune.paused) {
      setIsPlaying(false);
      startCallTune.pause();
    } else {
      setIsPlaying(true);
      startCallTune.play();
    }
  };

  const handletoggleMic = () => {
    const audioTracks = myStream.getAudioTracks();
    audioTracks.forEach((track) => (track.enabled = !micOn));
    socket.emit("changed_media", {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
      media_type: "is_muted",
      media_status: micOn,
    });
    setMicOn(!micOn);
  };

  const handleNoAnswered = () => {
    let socketData = {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
    };
    socket.emit("call:no_answered", socketData);
  };

  useEffect(() => {
    let timeoutId;
    if (isIncomingCall) {
      timeoutId = setTimeout(() => {
        if (!isCallAccepted) {
          handleNoAnswered();
        }
      }, 40000);
    }
    if (isCallAccepted) {
      clearTimeout(timeoutId);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isIncomingCall, isCallAccepted]);

  return (
    <>
      {isCallAccepted ? (
        <>
          <ParticipantView
            setBoxType={setBoxType}
            boxType={boxType}
            cameraOn={cameraOn}
          />
        </>
      ) : (
        <div className="image-box">
          <img src={logo} alt="" />
        </div>
      )}
      {boxType !== "CIRCLE" && (
        <div className="icon-box">
          {isIncomingCall ? (
            <>
              <Tooltip title={`Join Class`}>
                <div className="success-call-box" onClick={acceptCall}>
                  {isCallAccepting ? (
                    <CircularProgress
                      style={{
                        width: 20,
                        height: 20,
                        color: "#fff",
                      }}
                    />
                  ) : (
                    <Icon
                      fontSize="17"
                      className="start-call-icon"
                      icon={`carbon:phone-incoming`}
                    />
                  )}
                </div>
              </Tooltip>
              <Tooltip title={`${isPlaying ? "Off" : "On"}`}>
                <div className="success-call-box" onClick={handletoggleAudio}>
                  <Icon
                    fontSize="17"
                    className="start-call-icon"
                    icon={`${
                      isPlaying ? "fa-solid:volume-up" : "fa-solid:volume-mute"
                    }`}
                  />
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={`${micOn ? "Mute" : "Unmute"}`}>
                <div className="success-call-box" onClick={handletoggleMic}>
                  <Icon
                    fontSize="17"
                    className="start-call-icon"
                    icon={`${micOn ? "bi:mic-fill" : "vaadin:mute"}`}
                  />
                </div>
              </Tooltip>
              <Tooltip
                title={`${cameraOn ? "Turn Off Camera" : "Turn On Camera"}`}
              >
                <div
                  className="success-call-box"
                  onClick={() => handleToggleCam()}
                >
                  <Icon
                    fontSize="17"
                    className="start-call-icon"
                    icon={`${
                      cameraOn ? "ic:round-camera-alt" : "mdi:camera-off"
                    }`}
                  />
                </div>
              </Tooltip>
            </>
          )}

          <Tooltip
            title={`${
              isCallAccepting
                ? ""
                : isIncomingCall
                ? "Decline Call"
                : isCallAccepted
                ? "Drop Call"
                : "Cancel Call"
            }`}
            onClick={handleEndCall}
          >
            <div
              className={`success-call-box error ${
                isCallAccepting ? "call-accepting" : ""
              }`}
            >
              <Icon
                fontSize="17"
                className="start-call-icon"
                icon="subway:call-3"
              />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}
