import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { announcements_list_api } from "../../DAL/Announcements/Announcements";
import { useNavigate } from "react-router-dom";

export default function AnnouncementsList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [announcementsData, setAnnouncementsData] = useState([]);

  const get_announcements_list = async () => {
    setIsLoading(true);
    const result = await announcements_list_api(true);
    if (result.code === 200) {
      setAnnouncementsData(result.announcements);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDetail = (value) => {
    navigate(`/announcements/detail/${value._id}`, {
      state: value,
    });
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Title",
      handleClick: handleDetail,
      className: "cursor-pointer",
    },
    { id: "action", label: "Action", type: "action" },
  ];

  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: handleDetail,
    },
  ];

  useEffect(() => {
    get_announcements_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Announcements</h2>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={announcementsData}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
