import React, { useState } from "react";
import { logo } from "../../../assets";
import MeetingView from "./MeetingView";
import { useClassRoom } from "../../../Hooks/ClassRoom";

const DraggableBox = () => {
  const [boxType, setBoxType] = useState("BOX");
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { lastCallID } = useClassRoom();

  const handleMouseDown = (e) => {
    const initialX = e.clientX - position.x;
    const initialY = e.clientY - position.y;

    const handleMouseMove = (e) => {
      const newX = e.clientX - initialX;
      const newY = e.clientY - initialY;
      setPosition({ x: newX, y: newY });
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      className={`${
        boxType === "BOX"
          ? "draggable-box"
          : boxType === "CIRCLE"
          ? "circle-box"
          : "full-call-box"
      }`}
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
      onMouseDown={boxType === "BOX" ? handleMouseDown : () => {}}
      onClick={boxType === "CIRCLE" ? () => setBoxType("BOX") : () => {}}
    >
      {lastCallID ? (
        <MeetingView setBoxType={setBoxType} boxType={boxType} />
      ) : (
        <div className="image-box">
          <img src={logo} alt="" />
        </div>
      )}
    </div>
  );
};

export default DraggableBox;
