import { TextField } from "@mui/material";
import React, { useState } from "react";

export default function MarkDownLink({ handleAddLink }) {
  const [state, setState] = useState({
    display_text: "",
    link_address: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleAddLink(state);
  };

  return (
    <div className="change-my-password">
      <form className="input-form" onSubmit={handleSubmit}>
        <TextField
          className="mb-3 form-control"
          id="display_text"
          label="Text To Display"
          size="small"
          variant="outlined"
          name="display_text"
          value={state.display_text}
          required={true}
          onChange={handleChange}
        />
        <TextField
          type="url"
          className="mb-3 form-control"
          id="link_address"
          label="Link Address"
          size="small"
          variant="outlined"
          name="link_address"
          value={state.link_address}
          required={true}
          onChange={handleChange}
        />
        <div className="add--button text-end">
          <button>Save</button>
        </div>
      </form>
    </div>
  );
}
