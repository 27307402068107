import { invokeApi } from "../../bl_libs/invokeApi";

export const announcements_list_api = async () => {
  const requestObj = {
    path: `api/announcements/announcements_for_users`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const announcements_detail_api = async (announcements_id) => {
  const requestObj = {
    path: `api/announcements/${announcements_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const seen_announcement_api = async (announcements_id) => {
  const requestObj = {
    path: `api/announcements/seen_announcement/${announcements_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
